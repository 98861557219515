/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
// @ts-ignore
import MainTheme from "../../../styles/MainTheme";

interface IProps {
  title: string;
  valueSetter: (value: any) => void;
  valueArray: { label: any; value: any }[];
  initialState?: boolean;
  placeHolder?: string;
  itemHeight?: number;
  titleIsLink?: boolean;
  linkTo?: { screen: string; params?: {} };
  initialValue?: number;
}

interface ButtonProps {
  active: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #333333;
`;

const Title = styled.text`
  color: ${({ theme }) => theme.color.primaryFont};
  height: 100%;
  flex: 1;
`;

const Option = styled.button<ButtonProps>`
  display: flex;
  min-height: 40px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: ${({ theme, active }) =>
    active ? "1px solid" + theme.color.secondary : "none"};
  border-bottom: 1px solid
    ${({ theme, active }) =>
      active ? theme.color.secondary : theme.color.disabled};
  background-color: ${({ theme, active }) =>
    active ? theme.color.disabledBackground : theme.color.blackSettings};
  cursor: pointer;
  :first-child {
    border-radius: 5px 5px 0 0;
  }
  :last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: ${({ theme, active }) =>
      active ? "1px solid" + theme.color.secondary : "none"};
  }
`;

const OptionTitle = styled.text<ButtonProps>`
  color: ${({ theme }) => theme.color.primaryFont};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
`;

const OptionContainer = styled.div`
  width: 50%;
`;

const Link = styled.a``;

const CalculatorMultipleOptions: React.FC<IProps> = (props: IProps) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedTitle, setSelectedTitle] = useState('');
  const [resultReseted, setResultReseted] = useState(false);
  const [linkActive, setLinkActive] = useState(false)

  useEffect(() => {
    setResultReseted(true);
  }, [props.initialState]);

  useEffect(() => {
    if(resultReseted) {
      props.valueSetter(
        props.initialValue
          ? props.valueArray[props.initialValue].value
          : props.valueArray[0].value
      );
      setSelectedValue(
        props.initialValue
          ? props.valueArray[props.initialValue].value
          : props.valueArray[0].value
      );
      setSelectedTitle(
        props.initialValue
          ? props.valueArray[props.initialValue].label
          : props.valueArray[0].label
      );
    }
  },[resultReseted])

  useEffect(() => {
    setSelectedValue(props.initialValue ? props.valueArray[props.initialValue].value : props.valueArray[0].value)
    props.valueSetter(
      props.initialValue
        ? props.valueArray[props.initialValue].value
        : props.valueArray[0].value
    );      
    setSelectedTitle(
      props.initialValue
        ? props.valueArray[props.initialValue].label
        : props.valueArray[0].label
    );
  }, [])

  return (
    <ThemeProvider theme={MainTheme}>
      <Container>
        {props.titleIsLink ? (
          <Link
            onClick={() => setLinkActive(true)}
            style={{
              color: linkActive
                ? MainTheme.color.secondary
                : MainTheme.color.primary,
              flex: 1,
            }}
            href={props.linkTo?.screen}
          >
            {props.title}
          </Link>
        ) : (
          <Title>{props.title}</Title>
        )}
        <OptionContainer>
          {props?.valueArray?.map((choice) => (
            <Option
              onClick={() => {
                setSelectedValue(choice.value);
                setSelectedTitle(choice.label)
                props.valueSetter(choice.value);
                setResultReseted(false);
              }}
              active={choice.value === selectedValue && choice.label === selectedTitle}
            >
              <OptionTitle active={choice.value === selectedValue}>
                {choice.label}
              </OptionTitle>
            </Option>
          ))}
        </OptionContainer>
      </Container>
    </ThemeProvider>
  );
};

export default CalculatorMultipleOptions;
