/* eslint-disable no-new */
/* eslint-disable no-new-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CalculatorContainer,
  CalculatorTitle,
  Container,
  Main,
  ClearButton,
  TitleContainer,
  ContentContainer,
  // @ts-ignore
} from "./style";
import CalculatorBasicInput from "../../components/molecules/CalculatorBasicInput";
import CalculatorMultipleOptions from "../../components/molecules/CalculatorMultipleOptions";
// @ts-ignore
import { BBLoadingGif } from "../../blackbook-components/quarks/blackbook-loading";
import { CalcsList } from "./CalcsList";
import CalcDosResults from "../../components/organisms/CalculatorResults/calcDosResults";

interface IProps {
  calcName: string;
}

const CalcDos = (props: IProps) => {
  const [inputValues, setInputValues] = useState<any>({});
  const [parsedExpressions, setParsedExpressions] = useState<any>([]);
  const [results, setResults] = useState<any>();
  const [secondaryResults, setSecondaryResults] = useState<any>();
  const [initialState, setInitialState] = useState(true);
  const [fieldsComplete, setFieldsComplete] = useState(false);
  //@ts-ignore
  const calcContent = CalcsList[props.calcName];

  function evalValidator(expression: any) {
    let isValid;
    try {
      new Function(`return ${expression}`);
      isValid = true;
    } catch (e) {
      isValid = false; // A expressão tem erro de sintaxe
    }
    return isValid;
  }

  function visibleEvaluator(object: any) {
    if (object.visible === "true") {
      return true;
    } else if (Object.keys(inputValues).length <= 0) {
      return false;
    } else {
      let conditionalToEval;
      const conditionRegex = /\{(.*?)\}/gi;
      const conditions = object.visible.match(conditionRegex);
      let parsedConditional = object.visible
        .replaceAll("{", "")
        .replaceAll("}", "");
      let valueToReturn;
      conditions?.forEach((condition: any) => {
        const parsedCondition = condition.replace("{", "").replace("}", "");
        let value = inputValues?.[parsedCondition]?.value;
        if (value === undefined) {
          conditionalToEval = false;
          return false;
        }
        let finalAttribute = false;
        if (value) {
          while (!finalAttribute) {
            if (typeof value === "string") {
              finalAttribute = true;
            } else {
              value = value[0].value;
            }
          }
        }
        parsedConditional = parsedConditional.replace(
          parsedCondition,
          `"${value}"`
        );
        conditionalToEval = parsedConditional;
      });
      if (conditionalToEval) {
        try {
          valueToReturn = eval(conditionalToEval);
          return valueToReturn;
        } catch (error) {
        }
      }
    }
  }

  function calculationParser(initialExpression: string, ref: string, round?: string) {
    const cleanExpression = expressionParser(initialExpression);
    const isValid = evalValidator(cleanExpression);
    if (isValid) {
      setFieldsComplete(true);
      setParsedExpressions((prevState: any) => ({
        ...prevState,
        [ref]: {
          ...prevState[ref],
          value: round
            ? Math.round(eval(cleanExpression))
            : eval(cleanExpression).toFixed(1),
        },
      }));
    } else {
      setFieldsComplete(false);
      setResults({});
      setSecondaryResults({});
    }
  }

  function conditionalParser(
    initialExpression: string,
    ref: string,
    bypassSetter: boolean = false
  ) {
    const expressionArray = initialExpression.split(";");
    let bypassedResult;
    expressionArray.forEach((expression) => {
      const splitExpression = expression.split(")");
      const condition = splitExpression[0].replace("if(", "");
      const returnValue = splitExpression[1]
        ?.replace("return", "")
        .replace(" ", "");
      const expIndicatorRegex = /\[[^\]]*\]/i;
      const evaluatorRegex = /(?<=[a-zA-Z])[^a-zA-Z]*$/i;
      const evaluator = condition.match(evaluatorRegex);
      const unparsedIndicator = condition.match(expIndicatorRegex);
      if (unparsedIndicator && evaluator) {
        const indicatorProps = condition
          .split(unparsedIndicator[0])[1]
          .split(evaluator[0])[0];
        const propsArray = indicatorProps.split(".");
        propsArray.shift();
        const parsedIndicator = unparsedIndicator[0]
          .replace("[", "")
          .replace("]", "");
        let iterator = inputValues?.[parsedIndicator];
        propsArray.forEach((prop) => {
          if (Object.prototype.toString.call(iterator) === "[object Array]") {
            iterator = iterator[0]?.[prop];
          } else {
            iterator = iterator?.[prop];
          }
        });
        const isValid = evalValidator(iterator + evaluator);
        if (!isValid) {
          return;
        } else if (bypassSetter) {
          if (eval(iterator + evaluator)) {
            bypassedResult = returnValue;
            return;
          }
        } else if (eval(iterator + evaluator) && !bypassSetter) {
          setParsedExpressions((prevState: any) => ({
            ...prevState,
            [ref]: {
              ...prevState[ref],
              value: returnValue,
            },
          }));
        } else {
          return;
        }
      }
    });
    if (bypassSetter) {
      return bypassedResult;
    }
  }

  function inputValuesHelper(unparsed: string) {
    const ipValuesKeysRegex = /inputValues\[[^\]]*\]/gi;
    const ipValuesKey = unparsed.match(ipValuesKeysRegex);
    let valueToReturn = {};
    if (ipValuesKey) {
      ipValuesKey.forEach((key: string) => {
        const replaceLocation = key + unparsed.split(key)[1];
        const parsedkey = key
          .replace("inputValues", "")
          .replace("[", "")
          .replace("]", "");
        const props = unparsed.split(key)[1].split(".");
        let accumulator = inputValues[parsedkey];
        props.forEach((prop: string) => {
          if (prop === "") {
            return;
          } else {
            if (accumulator) {
              accumulator = accumulator[prop];
            }
          }
        });
        valueToReturn = { value: accumulator, whereToReplace: replaceLocation };
      });
      return valueToReturn;
    } else {
      return unparsed;
    }
  }

  function expressionParser(initialExpression: string) {
    const regex = /inputValues\[[^\]]*\]/gi;
    const expressionVariablesArray = [...initialExpression.matchAll(regex)];
    //@ts-ignore
    let cleanExpression = initialExpression.replaceAll(";", "");
    for (let index = 0; index < expressionVariablesArray.length; index++) {
      const expression = expressionVariablesArray[index];
      const initialObject = initialExpression
        .split(";")
        .filter((prop) => prop.includes("inputValues"));
      const ObjectProps = initialObject[index].split(".");
      let propsAccumulator = "";
      let value;
      ObjectProps.shift();
      const expressionIndicator = expression[0]
        .replace("inputValues[", "")
        .replace("]", "");
      ObjectProps.forEach((prop, i) => {
        if (i < ObjectProps.length - 1) {
          propsAccumulator += prop + ".";
        } else {
          propsAccumulator += prop;
        }
      });
      const splitProps = propsAccumulator.split(".");
      if (splitProps.length > 1) {
        let iterator = inputValues?.[expressionIndicator];
        splitProps.forEach((prop) => {
          if (Array.isArray(iterator)) {
            iterator = iterator[0]?.[prop];
          } else {
            iterator = iterator?.[prop];
          }
        });
        value = iterator;
      } else {
        value = inputValues?.[expressionIndicator]?.[propsAccumulator];
      }
      cleanExpression = cleanExpression.replace(
        expression[0] + "." + propsAccumulator,
        value
      );
    }
    return cleanExpression;
  }

  function secondaryResultsParser(fullObj: any) {
    let temp: { result: string; title: string; resultColor?: string }[] = [];
    let isValid = false;
    fullObj.secondaryResults.forEach((secondary: any) => {
      let parsedResult = secondary.result;
      secondary.formulas.forEach((formula: any) => {
        //@ts-ignore
        if (formula.type && formula.type === "conditional") {
          const result = conditionalParser(
            formula.expression,
            formula.ref,
            true
          );
          isValid = true;
          parsedResult = parsedResult
            //@ts-ignore
            .replace(formula.ref, result)
            .replace("{", "")
            .replace("}", "");
        } else {
          const result = expressionParser(formula.expression);
          isValid = evalValidator(result);
          if (isValid) {
            let parsed
            if (formula.round) {
              parsed = Math.round(eval(result))
            } else {
              parsed = eval(result);
            }
            parsedResult = parsedResult
              .replace(formula.ref, parsed)
              .replace("{", "")
              .replace("}", "");
          }
        }
      });
      if (isValid) {
        temp.push({
          result: parsedResult,
          title: secondary.title,
          resultColor: secondary.color,
        });
      }
    });
    setSecondaryResults(temp);
  }

  function resultsParser(fullObj: any) {
    if (!fieldsComplete) {
      return;
    } else {
      fullObj.results.forEach((result: any) => {
        if (!visibleEvaluator(result)) {
          return;
        } else {
          const resultRegex = /\{(.*?)\}/gi;
          const resultVariables = result.result.match(resultRegex);
          let parsedResult = result.result
            //@ts-ignore
            .replaceAll("{", "")
            .replaceAll("}", "");
          resultVariables?.forEach((variable: any) => {
            const variableName = variable.replace("{", "").replace("}", "");
            parsedResult = parsedResult.replace(
              variableName,
              parsedExpressions[variableName]?.value
            );
          });
          const subtitleObject = inputValuesHelper(result.subtitle);
          const parsedSubtitle = result.subtitle.replace(
            //@ts-ignore
            subtitleObject.whereToReplace,
            //@ts-ignore
            subtitleObject.value
          );
          setResults({
            title: result.title,
            value: parsedResult,
            subtitle: parsedSubtitle,
          });
        }
      });
    }
  }

  function inputSorter(input: any) {
    const isVisible = visibleEvaluator(input);
    if (!isVisible) {
      return;
    } else {
      if (input.type === "text") {
        return (
          <CalculatorBasicInput
            title={input.title}
            placeHolder={input.unity[0]}
            key={input.ref}
            keyboardType="decimal"
            value={inputValues[input.ref]?.value.replace(/^0+/, "") || ""}
            initialState={initialState}
            valueSetter={(e) =>
              setInputValues((prevState: any) => ({
                ...prevState,
                [input.ref]: {
                  ...prevState[input.ref],
                  value: e.replace(/^0+/, ''),
                  type: "text",
                },
              }))
            }
          />
        );
      }
      if (input.type === "select") {
        return (
          <CalculatorMultipleOptions
            initialState={initialState}
            title={input.title}
            key={input.ref}
            valueArray={input.options}
            valueSetter={(e) => {
              setInputValues((prevState: any) => ({
                ...prevState,
                [input.ref]: {
                  value: e,
                  type: "select",
                },
              }));
            }}
          />
        );
      }
    }
  }

  useEffect(() => {
    calcContent.results.forEach((result: any) => {
      if (!visibleEvaluator(result)) {
        return;
      } else {
        result.formulas.forEach((formula: any) => {
          if (formula.type === "calculation") {
            calculationParser(formula.expression, formula.ref, formula.round);
          } else if (formula.type === "conditional") {
            conditionalParser(formula.expression, formula.ref);
          }
        });
      }
    });
  }, [inputValues]);

  useEffect(() => {
    resultsParser(calcContent);
    secondaryResultsParser(calcContent);
  }, [parsedExpressions]);

  if (calcContent) {
    return (
      <>
        <Main>
          <Container>
            <CalculatorContainer>
              <TitleContainer>
                <CalculatorTitle>{calcContent.title}</CalculatorTitle>
              </TitleContainer>
              <ContentContainer>
                {calcContent.inputs.map((input: any) => inputSorter(input))}
              </ContentContainer>
              <ClearButton onClick={() => setInitialState(!initialState)}>
                LIMPAR
              </ClearButton>
            </CalculatorContainer>
          </Container>
          <CalcDosResults
            primaryResult={results && results.value}
            secondaryResults={secondaryResults}
            initialState={initialState}
            info={calcContent.info}
            title={results?.title + " - " + results?.subtitle}
          />
        </Main>
      </>
    );
  } else {
    return <BBLoadingGif />;
  }
};

export default CalcDos;
