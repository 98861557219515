/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
// @ts-ignore
import MainTheme from "../../../styles/MainTheme";

interface IProps {
  title: string;
  inputType?: "number" | "text";
  keyboardType?:
    | "numeric"
    | "url"
    | "search"
    | "text"
    | "none"
    | "tel"
    | "email"
    | "decimal"
    | undefined;
  valueSetter: (value: any) => void;
  value: string | number;
  placeHolder?: string;
  initialValue?: string;
  initialState?: boolean;
  titleIsLink?: boolean;
  linkTo?: { screen: string; params?: {} };
}

const Container = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background-color: #333333;
`;

const Title = styled.text`
  color: ${({ theme }) => theme.color.primaryFont};
  display: flex;
  flex: 1;
`;

const Input = styled.input`
  padding: 0px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  height: 40px;
  width: 50%;
  background-color: ${({ theme }) => theme.color.blackSettings};
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  margin-left: 10px;
  color: ${({ theme }) => theme.color.primaryFont};
  ::placeholder {
    color: ${({ theme }) => theme.color.disabled};
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Link = styled.a``;

const CalculatorBasicInput: React.FC<IProps> = (props: IProps) => {
  const [resultReseted, setResultReseted] = useState(false);
  const [linkActive, setLinkActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setResultReseted(true);
    props.valueSetter("");
  }, [props.initialState]);

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const handleWheel = (e: WheelEvent) => {
        // Somente previne a rolagem se o input estiver focado
        if (document.activeElement === input) {
          e.preventDefault();
        }
      };

      // Adiciona o evento de rolagem com passive: false
      input.addEventListener("wheel", handleWheel, { passive: false });

      // Remove o evento ao desmontar o componente
      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  return (
    <ThemeProvider theme={MainTheme}>
      <Container>
        {props.titleIsLink ? (
          <Link
            onClick={() => setLinkActive(true)}
            style={{
              color: linkActive
                ? MainTheme.color.secondary
                : MainTheme.color.primary,
              flex: 1,
            }}
            href={props.linkTo?.screen}
          >
            {props.title}
          </Link>
        ) : (
          <Title>{props.title}</Title>
        )}
        <Input
          ref={inputRef} // Referencia o input para manipulação do evento wheel
          inputMode={props.keyboardType || "decimal"}
          type={props.inputType || "number"}
          maxLength={6}
          placeholder={props.placeHolder || "..."}
          onChange={(e: any) => {
            props.valueSetter(e.target.value);
            if (resultReseted) {
              setResultReseted(false);
            }
          }}
          value={resultReseted ? "" : props.value}
          defaultValue={props.initialValue || ""}
        />
      </Container>
    </ThemeProvider>
  );
};

export default CalculatorBasicInput;
