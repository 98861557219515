/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// @ts-ignore
import MainTheme from "../../../styles/MainTheme";
// @ts-ignore
import useCalcLinks from "../../../Pages/WebAppCalculators/useCalcLinks";
import DosageInfo from "../../../Pages/WebAppCalcDoses/Info";
interface IProps {
  initialState: any;
  color?: string;
  primaryResult: any;
  secondaryResults: any;
  info: any;
  title: string;
}

interface IStyle {
  borderColor?: string;
  active?: boolean;
  resultColor?: string;
}

const HeaderContainer = styled.section`
  height: 48px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  background-color: ${MainTheme.color.blackSettings};
  border-bottom: 2px solid ${MainTheme.color.primary};
`;

const HeaderButton = styled.button<IStyle>`
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  background-color: ${({ active }) =>
    active
      ? MainTheme.color.disabledBackground
      : MainTheme.color.blackSettings};
  :first-child {
    border-top-left-radius: 5px;
  }
  :last-child {
    border-top-right-radius: 5px;
  }
`;

const Container = styled.div`
  max-height: calc(100vh - 130px);
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1025px) {
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
  }
`;

const Results = styled.section`
  max-height: calc(100vh - 178px);
  @media screen and (max-width: 1025px) {
    width: 100%;
    overflow-y: scroll;
  }
  box-sizing: border-box;
  width: 100%;
  background-color: ${MainTheme.color.blackSettings};
  padding-top: 10px;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`;

const MainContainer = styled.div<IStyle>`
  border-bottom: 2px solid
    ${({ borderColor }) =>
      borderColor ? borderColor : MainTheme.color.primary};
  padding: 10px;
`;

const MainResults = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
`;

const Title = styled.h1<IStyle>`
  color: ${({ resultColor }) => (resultColor ? resultColor : "white")};
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ResultValue = styled.h2<IStyle>`
  color: ${({ resultColor }) => (resultColor ? resultColor : "white")};
  font-size: 2rem;
  font-weight: 700;
`;

const SecondaryResults = styled.div`
  overflow-y: scroll;
  padding: 10px;
  margin: 0 10px;
  border-radius: 5px;
  background-color: #333333;
  @media screen and (max-width: 1025px) {
    overflow-y: initial;
  }
`;

const SecondaryContainer = styled.div`
  margin: 10px 0;
`;

const SecondaryTitle = styled.h3<IStyle>`
  color: ${({ resultColor }) => (resultColor ? resultColor : "white")};
  font-size: 1.1rem;
  font-weight: 700;
`;

const SecondaryInterpretation = styled.p<IStyle>`
  color: ${({ resultColor }) => (resultColor ? resultColor : "white")};
  font-size: 1.1rem;
  flex: 1;
`;

const SecondaryResult = styled.p<IStyle>`
  color: ${({ resultColor }) => (resultColor ? resultColor : "white")};
  font-size: 1.5rem;
  font-weight: 700;
  white-space: pre-line;
`;

const LinkText = styled.p`
  color: #8774ff;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
`;

const LinkContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  p {
    display: inline;
  }
`;

const SecondaryTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid white;
`;

const SecondaryTableItem = styled.p`
  flex: 1;
  color: white;
  padding: 10px;
  :first-child {
    border-right: 1px solid white;
  }
`;

const CalcDosResults = (props: IProps) => {
  const [activeButton, setActiveButton] = useState<"results" | "about">(
    "about"
  );
  const handleNav = useCalcLinks();

  function linkParser(value: any) {
    if (value?.result?.includes("#")) {
      const fullUnparsed = value.result.split("#");
      const arrayToReturn: any = [];
      fullUnparsed?.forEach((string: string) => {
        const regex = /{(.*?)}/gm;
        const linkParams = string.match(regex);
        if (string?.includes("{") && linkParams) {
          const textToAdd = string.replace(`${linkParams[0]}`, "");
          arrayToReturn.push(
            <LinkText onClick={() => handleNav(JSON.parse(`${linkParams[0]}`))}>
              {textToAdd}
            </LinkText>
          );
        } else {
          arrayToReturn.push(string);
        }
      });
      return (
        <SecondaryResult resultColor={value.resultColor}>
          {arrayToReturn}
        </SecondaryResult>
      );
    } else {
      return (
        <SecondaryResult resultColor={value.resultColor || ""}>
          {value.result}
        </SecondaryResult>
      );
    }
  }

  useEffect(() => {
    setActiveButton("about");
  }, [props.initialState]);

  useEffect(() => {
    if(props.primaryResult) {
      setActiveButton('results')
    } else {
      setActiveButton('about')
    }
  }, [props.primaryResult])

  return (
    <Container>
      <HeaderContainer>
        {props.primaryResult && (
          <>
            <HeaderButton
              key={"results"}
              disabled={!props.primaryResult}
              active={activeButton === "results"}
              onClick={() => {
                if (props.primaryResult) {
                  setActiveButton("results");
                }
              }}
            >
              Resultados
            </HeaderButton>
            <div
              style={{
                border: `1px solid ${MainTheme.color.primary}`,
                height: 40,
              }}
            ></div>
          </>
        )}
        <HeaderButton
          key={"about"}
          active={activeButton === "about"}
          onClick={() => {
            setActiveButton("about");
          }}
        >
          Sobre a calculadora
        </HeaderButton>
      </HeaderContainer>
      {activeButton === "about" && (
        <Results>
          <SecondaryResults>
            <SecondaryContainer>
              <DosageInfo content={props.info} />
            </SecondaryContainer>
          </SecondaryResults>
        </Results>
      )}
      {props.primaryResult && activeButton === "results" && (
        <Results>
          <MainContainer borderColor={props.color}>
              <Title>{props.title}</Title>
            <MainResults>
              <ResultValue resultColor={props.color}>
                {props.primaryResult}
              </ResultValue>
            </MainResults>
          </MainContainer>

          {props.secondaryResults && (
            <SecondaryResults>
              {props.secondaryResults?.map((result: any) => {
                if (result.result) {
                  if (result.table) {
                    return (
                      <div style={{ marginTop: 10 }} key={result.title}>
                        <SecondaryResult style={{ marginBottom: 5 }}>
                          {result.title}
                        </SecondaryResult>
                        {result.result.map((tableItem: any) => (
                          <SecondaryTable key={tableItem.title}>
                            <SecondaryTableItem>
                              {tableItem.title}
                            </SecondaryTableItem>
                            <SecondaryTableItem
                              style={{
                                color: tableItem.color
                                  ? tableItem.color
                                  : "white",
                              }}
                            >
                              {tableItem.value}
                            </SecondaryTableItem>
                          </SecondaryTable>
                        ))}
                      </div>
                    );
                  }
                  if (
                    !result.table &&
                    typeof result.result === "string" &&
                    !result.result.includes("NaN")
                  )
                    return (
                      <SecondaryContainer>
                        <SecondaryTitle>{result.title}</SecondaryTitle>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          {result.resultHasLink ? (
                            <LinkContentContainer>
                              {linkParser(result)}
                            </LinkContentContainer>
                          ) : (
                            <SecondaryResult
                              resultColor={result.resultColor || ""}
                            >
                              {result.result}
                            </SecondaryResult>
                          )}

                          <SecondaryInterpretation>
                            {result.interpretation}
                          </SecondaryInterpretation>
                        </div>
                      </SecondaryContainer>
                    );
                }
              })}
            </SecondaryResults>
          )}
        </Results>
      )}
    </Container>
  );
};

export default CalcDosResults;
