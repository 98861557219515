export const CalcsList = {
  Dipirona: {
    title: "Dipirona",
    href: "drugs/604a3fcc4d6cd55259274704/604a2f6bcbe441cf2f213c27",
    info: 'Dose usual para dor ou febre pela via oral:;@10 a 15 mg/kg/dose até a cada 6 horas.;Dose usual pela via parenteral (intramuscular ou endovenosa):;@15 mg/kg/dose.;Dose máxima: 25 mg/kg/dose;Consultar #{"origin": "Dipirona", "type": "drug", "mainId": "604a3fcc4d6cd55259274704", "secondaryId": "604a2f6bcbe441cf2f213c27"}outras doses e indicações mais específicas#.',
    inputs: [
      {
        title: "Peso",
        ref: "peso",
        unity: ["kg"],
        type: "text",
        options: [],
        order: 0,
        visible: "true",
      },
      {
        title: "Via de administração",
        ref: "via",
        unity: [""],
        type: "select",
        order: 1,
        visible: "true",
        options: [
          {
            label: "Oral",
            value: [{ ref: "Oral", value: "Oral", key: 10 }],
            key: 1,
          },
          {
            label: "EV",
            value: [{ ref: "EV", value: "EV", key: 11 }],
            key: 2,
          },
          {
            label: "IM",
            value: [{ ref: "IM", value: "IM", key: 12 }],
            key: 3,
          },
        ],
      },
      {
        title: "Dose (máximo 25 mg/kg)",
        ref: "dose",
        unity: ["mg/kg/dose"],
        type: "text",
        options: [],
        order: 2,
        visible: "true",
      },
      {
        title: "Nº doses por dia",
        ref: "numDose",
        unity: [""],
        type: "select",
        visible: "true",
        options: [
          {
            label: "1",
            value: [
              {
                ref: "1",
                value: 1,
              },
            ],
          },
          {
            label: "2",
            value: [
              {
                ref: "2",
                value: 2,
              },
            ],
          },
          {
            label: "3",
            value: [
              {
                ref: "3",
                value: 3,
              },
            ],
          },
          {
            label: "4",
            value: [
              {
                ref: "4",
                value: 4,
              },
            ],
          },
          {
            label: "5",
            value: [
              {
                ref: "5",
                value: 5,
              },
            ],
          },
          {
            label: "6",
            value: [
              {
                ref: "6",
                value: 6,
              },
            ],
          },
        ],
        order: 3,
      },
      {
        title: "Apresentação",
        ref: "apresenta",
        unity: [""],
        type: "select",
        visible: "{via} === 'Oral'",
        options: [
          {
            label: "Gotas  (500 mg/mL)",
            value: {
              ref: 55,
              title: {
                value: "Gotas  (500 mg/mL)",
              },
              concentration: {
                value: 25,
              },
            },
          },
          {
            label: "Xarope (250 mg/5 mL)",
            value: {
              ref: 56,
              title: {
                value: "Xarope (250 mg/5 mL)",
              },
              concentration: {
                value: 50,
              },
            },
          },
        ],
        order: 5,
      },
    ],
    results: [
      {
        title: "USO ORAL",
        subtitle: "Dipirona inputValues[apresenta].value.title.value",
        visible: "{via} === 'Oral' && {peso} >= 0 && {dose} >= 0",
        result:
          "Dar {resultCalc} {presentation} até a cada {time} horas, se necessário",
        formulas: [
          {
            ref: "resultCalc",
            type: "calculation",
            round: "true",
            expression:
              "inputValues[dose].value;*;inputValues[peso].value;/;inputValues[apresenta].value.concentration.value",
          },
          {
            ref: "time",
            type: "conditional",
            expression:
              "if(inputValues[numDose].value.value===1)return 24;if(inputValues[numDose].value.value===2)return 12;if(inputValues[numDose].value.value===3)return 8;if(inputValues[numDose].value.value===4)return 6;if(inputValues[numDose].value.value===6)return 4;",
          },
          {
            ref: "presentation",
            type: "conditional",
            expression:
              "if(inputValues[apresenta].value.ref===55)return gotas;if(inputValues[apresenta].value.ref===56)return mL;",
          },
        ],
      },
      {
        title: "USO INTRAMUSCULAR",
        subtitle: "Dipirona 500 mg/mL - ampola 2 mL (solução injetável)",
        visible: "{via} === 'IM' && {peso} >= 0 && {dose} >= 0",
        result:
          "Administrar {resultCalc} ml IM, até a cada 6 horas, se necessário.",
        formulas: [
          {
            ref: "resultCalc",
            type: "calculation",
            expression:
              "(inputValues[peso].value;*;inputValues[dose].value;);/;500",
          },
        ],
      },
      {
        title: "USO INTRAVENOSO",
        subtitle: "Dipirona 500 mg/mL - ampola 2 mL (solução injetável)",
        visible: '{via} === "EV" && {peso} >= 0 && {dose} >= 0',
        result:
          "Administrar {resultCalc} mg EV, até a cada {time} horas se necessário. (Equivale a {quantity} mL da ampola)",
        formulas: [
          {
            ref: "resultCalc",
            type: "calculation",
            expression: "inputValues[peso].value;*;inputValues[dose].value",
          },
          {
            ref: "time",
            type: "conditional",
            expression:
              "if(inputValues[numDose].value.value===1)return 24;if(inputValues[numDose].value.value===2)return 12;if(inputValues[numDose].value.value===3)return 8;if(inputValues[numDose].value.value===4)return 6;if(inputValues[numDose].value.value===6)return 4;",
          },
          {
            ref: "quantity",
            type: "calculation",
            expression:
              "(inputValues[peso].value;*;inputValues[dose].value;);/;500",
          },
        ],
      },
    ],
    secondaryResults: [
      {
        title: "",
        result: "{result}",
        color: "#D53B30",
        formulas: [
          {
            type: "conditional",
            expression:
              "if(inputValues[dose].value>25)return Atenção! Ultrapassou a dose máxima: 25 mg/kg;if(inputValues[dose].value<=25)return ",
            ref: "result",
          },
        ],
      },
      {
        title: "Dose em mg",
        result: "{result} mg/dose",
        formulas: [
          {
            expression: "inputValues[peso].value;*;inputValues[dose].value",
            ref: "result",
          },
        ],
      },
      {
        title: "Dose diária",
        result: "{result} mg/dia",
        formulas: [
          {
            expression:
              "inputValues[peso].value;*;inputValues[dose].value;*;inputValues[numDose].value.value",
            ref: "result",
          },
        ],
      },
      {
        title: "Dose máxima",
        result: "{result} mg/dia ou {alternate} mg/dose",
        formulas: [
          {
            expression:
              "25;*;inputValues[peso].value;*;inputValues[numDose].value.value",
            ref: "result",
          },
          {
            expression:
              "(25;*;inputValues[peso].value;*;inputValues[numDose].value.value;);/;inputValues[numDose].value.value",
            ref: "alternate",
          },
        ],
      },
    ],
  },
  Paracetamol: {
    title: "Paracetamol",
    href: "drugs/604a3fcc4d6cd55259274704/604a2f4ccbe441cf2f213c16",
    info: 'Dose usual para dor ou febre:;@10 a 15 mg/kg/dose até a cada 6 horas.;Dose máxima: 65 mg/kg/dia ou 4.000 mg/dia (o que for menor).;Consultar #{"origin": "Paracetamol", "type": "drug", "mainId": 604a3fcc4d6cd55259274704", "secondaryId": "604a2f4ccbe441cf2f213c16"}outras doses e indicações mais específicas#.',
    inputs: [
      {
        title: "Peso",
        ref: "peso",
        unity: ["kg"],
        type: "text",
        options: [],
        order: 0,
        visible: "true",
      },
      {
        title: "Dose",
        ref: "dose",
        unity: ["mg/kg/dose"],
        type: "text",
        options: [],
        order: 1,
        visible: "true",
      },
      {
        title: "Nº doses por dia",
        ref: "numDose",
        unity: [""],
        type: "select",
        visible: "true",
        options: [
          {
            label: "1",
            value: [
              {
                ref: "1",
                value: 1,
              },
            ],
          },
          {
            label: "2",
            value: [
              {
                ref: "2",
                value: 2,
              },
            ],
          },
          {
            label: "3",
            value: [
              {
                ref: "3",
                value: 3,
              },
            ],
          },
          {
            label: "4",
            value: [
              {
                ref: "4",
                value: 4,
              },
            ],
          },
          {
            label: "6",
            value: [
              {
                ref: "6",
                value: 6,
              },
            ],
          },
        ],
        order: 2,
      },
      {
        title: "Apresentação",
        ref: "apresenta",
        unity: [""],
        type: "select",
        visible: "true",
        options: [
          {
            label: "Gotas  (200 mg/mL)",
            value: {
              ref: 55,
              title: {
                value: "Gotas  (200 mg/mL)",
              },
              concentration: {
                value: 13.3,
              },
            },
          },
          {
            label: 'Suspensão "Bebê" (500 mg/5 mL)',
            value: {
              ref: 56,
              title: {
                value: 'Suspensão "Bebê" (500 mg/5 mL)',
              },
              concentration: {
                value: 100,
              },
            },
          },
          {
            label: 'Suspensão "Criança" (160 mg/5 mL)',
            value: {
              ref: 56,
              title: {
                value: 'Suspensão "Criança" (160 mg/5 mL)',
              },
              concentration: {
                value: 32,
              },
            },
          },
        ],
        order: 5,
      },
    ],
    results: [
      {
        title: "USO ORAL",
        subtitle: "Paracetamol inputValues[apresenta].value.title.value",
        visible: "{peso} >= 0 && {dose} >= 0",
        result:
          "Dar {resultCalc} {presentation} até a cada {time} horas, se necessário",
        formulas: [
          {
            ref: "resultCalc",
            type: "calculation",
            round: "true",
            expression:
              "inputValues[dose].value;*;inputValues[peso].value;/;inputValues[apresenta].value.concentration.value",
          },
          {
            ref: "time",
            type: "conditional",
            expression:
              "if(inputValues[numDose].value.value===1)return 24;if(inputValues[numDose].value.value===2)return 12;if(inputValues[numDose].value.value===3)return 8;if(inputValues[numDose].value.value===4)return 6;if(inputValues[numDose].value.value===6)return 4;",
          },
          {
            ref: "presentation",
            type: "conditional",
            expression:
              "if(inputValues[apresenta].value.ref===55)return gotas;if(inputValues[apresenta].value.ref===56)return mL;",
          },
        ],
      },
    ],
    secondaryResults: [
      {
        title: "Dose em mg",
        result: "{result} mg/dose",
        formulas: [
          {
            expression: "inputValues[peso].value;*;inputValues[dose].value",
            ref: "result",
          },
        ],
      },
      {
        title: "Dose diária",
        result: "{result} mg/dia",
        formulas: [
          {
            expression:
              "inputValues[peso].value;*;inputValues[dose].value;*;inputValues[numDose].value.value",
            ref: "result",
          },
        ],
      },
      {
        title: "Dose máxima",
        result: "{result} mg/dia ou {alternate} mg/dose",
        formulas: [
          {
            expression: "65;*;inputValues[peso].value;",
            ref: "result",
          },
          {
            expression:
              "(65;*;inputValues[peso].value;);/;inputValues[numDose].value.value",
            ref: "alternate",
            round: "true",
          },
        ],
      },
    ],
  },
};
