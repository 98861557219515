import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './privateRoutes';

import { UnderConstruction } from '../Pages/UnderConstruction';
import { Home } from '../Pages/Home';
import { LandingPage } from '../Pages/LandingPage';
import { SignIn } from '../Pages/SignIn';
import { NotFoundSignedIn } from '../Pages/NotFound/signedIn';
import { Payment } from '../Pages/newPayment';
import { Plans } from '../Pages/newPlans';
import { WebAppMyBlackbook } from '../Pages/WebAppMyBlackbook';
import { UpdatePayment } from '../Pages/UpdatePayment';
import { ManagePlan } from '../Pages/ManagePlan';
import { DownloadPage } from '../Pages/DownloadPage';
import { ForgotPassword } from '../Pages/ForgotPassword';
import { GiftCard } from '../Pages/GiftCard';
import PublicRoute from './publicRoute';
import { SignUp } from '../Pages/SignUp';
import { WebAppHome } from '../Pages/WebAppHome';
import { WebAppDrugs } from '../Pages/WebAppDrugs';
import { WebAppRoutines } from '../Pages/WebAppRoutines';
import AppRoute from './appRoutes';
import { HiredPlanThanks } from '../Pages/HiredPlanThanks';
import { useGetQuery } from '../services/hooks/useGetQuery';
import { WebAppCalculators } from '../Pages/WebAppCalculators';
import { WebAppCID } from "../Pages/WebAppCID";
import { Partner } from '../Pages/Partner';
import { WebAppSearch } from '../Pages/WebAppSearch';
// import { NotFoundSignedOut } from "../Pages/NotFound/signedOut";

const Routers = () => {
  const query = useGetQuery();
  if (query.get('search')) {
    console.log("yau")
    localStorage.setItem('search_by_query', query.get('search'));
  }
  return (
    <Switch>
      {/* Public Routes */}

      {/* APP WEB ROUTES */}
      <AppRoute
        exact
        path="/app/search/:search?"
        component={WebAppSearch}
      />
      <AppRoute exact path="/app/:search?" component={WebAppHome} />
      <AppRoute
        exact
        path="/app/drugs/:therapeutic_group_id/:active_principle_id?/:section_id?"
        component={WebAppDrugs}
      />
      <AppRoute
        exact
        path="/app/routines/:routine_id/:tab_id?/:card_id?/:content_id?"
        component={WebAppRoutines}
      />
      <AppRoute
        exact
        path="/app/calculators/:calc_name"
        component={WebAppCalculators}
        reRendersOnLoad={true}
      />
      <AppRoute
        exact
        path="/app/CID/:cid_chapter?/:cid_group?/:cid_category?"
        component={WebAppCID}
      />
      {/* <AppRoute
        exact
        path="/app/search/:search?"
        component={WebAppSearch}
      /> */}

      {/* CUSTOMER MANAGER ROUTES */}
      <PublicRoute exact path="/signup" component={SignUp} />

      {/* Comentado e substituído aguardando lançamento oficial do site */}
      <PublicRoute exact path="/signin" component={SignIn} />

      {/* Login de campanhas */}
      <Route exact path="/partner/:campaign?" component={Partner} />

      <Route exact path="/forgotpassword" component={ForgotPassword} />
      {/* <Route exact path="/signin" component={LandingPage} /> */}
      <Route exact path="/download" component={DownloadPage} />

      {/* Private Routes */}
      <PrivateRoute exact path="/giftcard" component={GiftCard} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/payment/:name" component={Payment} />
      <PrivateRoute exact path="/plans" component={Plans} />
      <PrivateRoute exact path="/myblackbook" component={WebAppMyBlackbook} />
      <PrivateRoute exact path="/paymentmethod" component={UpdatePayment} />
      <PrivateRoute
        exact
        path="/thanks/:hired_plan?"
        component={HiredPlanThanks}
      />

      <PrivateRoute exact path="/manageplan" component={ManagePlan} />

      <PrivateRoute
        exact
        path="/underconstruction"
        component={UnderConstruction}
      />

      <PublicRoute exact path="/:indicator_code?" component={LandingPage} />
      {/* 404 */}
      <PrivateRoute component={NotFoundSignedIn} />
      {/* <Route component={NotFoundSignedOut}/> */}
    </Switch>
  );
};

export { Routers };
