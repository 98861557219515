import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import PrivateRoute from './privateRoutes';
import { useIsDesktopOrTabletScreen } from '../services/hooks/useIsDesktopOrTabletScreen';

const AppRoute = ({ component: Component, ...rest }) => {
  const isDesktopOrTabletScreen = useIsDesktopOrTabletScreen()

  if (!rest.reRendersOnLoad) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isDesktopOrTabletScreen ? (
            <PrivateRoute {...props} component={Component} />
          ) : (
            <Redirect to={{ pathname: '/download' }} />
          )
        }
      />
    );
  } else {
      return (
        <Route
          {...rest}
          render={(props) =>
            isDesktopOrTabletScreen ? (
              <PrivateRoute
                {...props}
                component={(privateProps) => (
                  <Component {...privateProps} key={"calc"} />
                )}
              />
            ) : (
              <Redirect to={{ pathname: "/download" }} />
            )
          }
        />
      );
  }
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default AppRoute;
