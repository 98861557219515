/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCalcLinks from "../WebAppCalculators/useCalcLinks";

interface IProps {
  content: string;
}

const InfoContainer = styled.ul`
  padding-left: 24px;
`;

const Text = styled.li`
  color: white;
  margin: 10px 0;
  font-size: 1.1rem;
`;

const ListItem = styled.div`
  margin-left: 10px;
`;


// const Italic = styled.Text`
//   color: white;
//   font-style: italic;
// `;

// const Bold = styled.Text`
//   color: white;
//   font-weight: bold;
// `;

const SubListItem = styled.ul`
  margin-left: 20px;
`;

const LinkText = styled.a`
  color: #8774ff;
  cursor: pointer;
`;

const DosageInfo: React.FC<IProps> = (props: IProps) => {
  const [contentArray, setContentArray] = useState<any>([]);
  const handleNav = useCalcLinks();

  function linkParser(value: any) {
    if (value.includes("#")) {
      const fullUnparsed = value.split("#");
      const arrayToReturn: any = [];
      fullUnparsed?.forEach((string: string) => {
        const regex = /(?<={)(.*)(?=})/gm;
        const linkParams = string.match(regex);
        if (string?.includes("{") && linkParams) {
          const textToAdd = string.replace(`{${linkParams[0]}}`, "");
          arrayToReturn.push(
            <LinkText
              onClick={() => handleNav(JSON.parse(`{${linkParams[0]}}`))}
            >
              {textToAdd}
            </LinkText>
          );
        } else {
          arrayToReturn.push(string);
        }
      });
      return (
        <Text>
          {"\u2022 "}
          {arrayToReturn}
        </Text>
      );
    }
  }

  useEffect(() => {
    const temp = props.content.split(";");
    setContentArray(temp);
  }, []);

  return (
    <InfoContainer>
          {contentArray.map((content: any) => {
            if (content.includes("@")) {
              const parsed = content.replace("@", "");
              return (
                <SubListItem>
                  <Text>{`${parsed}`}</Text>
                </SubListItem>
              );
            } else if (content.includes("#")) {
              const parsed = linkParser(content);
              return <ListItem>{parsed}</ListItem>;
            } else {
              return (
                <ListItem>
                  <Text>{`${content}`}</Text>
                </ListItem>
              );
            }
          })}
    </InfoContainer>
  );
};

export default DosageInfo;
